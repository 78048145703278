import * as React from "react"
import PropTypes from "prop-types"
import Silo from "./silo"
import SiloData from "./silo-data"

const Silos = ({
    sectionSilos,
}: {
    sectionSilos: Array<SiloData>
}) => (
    <div style={{display: `flex`, flexWrap: `wrap`}}>
       { sectionSilos.map((siloData, index) => {
            return <Silo siloItem={siloData} />
        })}
    </div>
)

export default Silos