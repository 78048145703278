import * as React from "react"
import { Link } from "gatsby"
import SiloStaticData from "../../static-content/silos-code.yml"

import LayoutContent from "../../components/layout-content"
import Seo from "../../components/seo"
import Silos from "../../components/silos/silos"

const CodeDefaultPage = () => (
  <LayoutContent>
    <Seo title="CV" />
    
    <h1>Code</h1>
    <Silos sectionSilos={SiloStaticData.silos}></Silos>
  </LayoutContent>
)

export default CodeDefaultPage