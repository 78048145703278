import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import "./layout-content.css"
import "./prism-customized.css"

const LayoutContent = ({ children }:{children:any}) => {
  return (
    <Layout>
      {children}
    </Layout>
  )}

export default LayoutContent
