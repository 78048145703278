import * as React from "react"
import PropTypes from "prop-types"
import SiloData from "./silo-data"
import { Link } from "gatsby"

import "./silo.css"

const Silo = ({ siloItem }: { siloItem: SiloData }) => (
  <div className="silo">
    <h2>
      {siloItem.title}
    </h2>
    <p>
      {siloItem.description}
    </p>
    <ul style={{listStyleType: `none`, margin: 0, padding: 0}}>
      {siloItem.links.map((link, i) => {
        return (
          <li>
            <Link to={link.link}>{link.title}</Link>
          </li>
        )
      })}
    </ul>
  </div>
)

Silo.propTypes = {
    siloItem: PropTypes.object
}

Silo.defaultProps = {
    siloItem: {}
}



export default Silo